@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: "Anton";
  src:url("/assets/fonts/Anton.ttf")
}

@font-face {
  font-family: "Circo";
  src:url("/assets/fonts/Circo.ttf")
}

@font-face {
  font-family: "GoboldHollow";
  src:url("/assets/fonts/GoboldHollow.otf")
}

@font-face {
  font-family: "GoboldRegular";
  src:url("/assets/fonts/GoboldRegular.otf")
}

@font-face {
  font-family: "GoboldHollowBold";
  src:url("/assets/fonts/GoboldHollowBold.otf")
}

@font-face {
  font-family: "MontserratRegular";
  src:url("/assets/fonts/MontserratRegular.ttf")
}

@font-face {
  font-family: "Montserrat";
  src:url("/assets/fonts/Montserrat-Regular.ttf")
}

@font-face {
  font-family: "MontserratMedium";
  src:url("/assets/fonts/Montserrat-Medium.ttf")
}

@font-face {
  font-family: "MontserratLight";
  src:url("/assets/fonts/Montserrat-Light.ttf")
}

@font-face {
  font-family: "HiraginoLight";
  src:url("/assets/fonts/hiraginoLight.ttc")
}

@font-face {
  font-family: "Hiragino";
  src:url("/assets/fonts/hiragino.ttc")
}

@font-face {
  font-family: "HiraginoMedium";
  src:url("/assets/fonts/hiraginoMedium.ttc")
}

@font-face {
  font-family: "HiraginoSemibold";
  src:url("/assets/fonts/hiraginoSemibold.ttc")
}

@font-face {
  font-family: "HiraginoBold";
  src:url("/assets/fonts/hiraginoBold.ttc")
}

.content p {
  @apply my-6;
}

.content ul {
  @apply my-6;
}

.text-stroke {
  color: black;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.active {
	border-bottom: 5px solid #2e2e2e;
}

.slick-track {
  display: flex !important;
  align-items: stretch;
}

.slick-slide {
  height: auto !important;

}

.slick-slide > div:first-child {
  height: 95%;
}

.slick-prev:before, .slick-next:before {
  color: #d2d2d2 !important;
  font-size: 25px !important;
}
.footerPylonImage span {
  top: 115px;
  left: 10px;
}

@media screen and (min-width: 768px) {
  .faqPylonImage span {
    top: 135px;
    left: -430px;
    margin-top: -30px !important;
  }
  .faqPylonImage img{
    height: 250px !important;
    width: 350px !important;
  }
}

@media screen and (max-width: 768px) {
  .footerPylonImage span {
    top: 100px;
    left: 15px;
  }
  .footerPylonImage img {
    height: 150px !important;
    width: 65px !important;
  }
  .faqPylonImage span {
    top: 100px;
    left: -10px;
  }
  .faqPylonImage img {
    height: 250px !important;
    width: 350px !important;
  }
  .servicesImage span {
    min-width: 250px;
  }
}
@media screen and (max-width: 400px) {
  .footerPylonImage span {
    top: 80px;
    left: 15px;
  }
  .footerPylonImage img {
    height: 140px !important;
    width: 60px !important;
  }
  .faqPylonImage span {
    top: 75px;
    left: 5px;
  }
  .faqPylonImage img {
    height: 190px !important;
    width: 230px !important;
  }
}
@media screen and (max-width: 320px) {
  .footerPylonImage span {
    top: 62px;
    left: 12px;
  }
  .footerPylonImage img {
    height: 90px !important;
    width:30px !important;
  }
  .faqPylonImage span {
    top: 62px;
    left: 12px;
  }
  .faqPylonImage img {
    height: 150px !important;
    width: 180px !important;
  }
  .servicesImage span {
    min-width: 150px;
  }
  .servicesImage .pylon span {
    min-width: 90px
  }
}

#headerBackground {
  background-position: bottom
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.ml6 {
  position: relative;
}

.ml6 .text-wrapper {
  position: relative;
  display: inline-block;
  overflow: hidden;
  height:20px;
}

.ml6 .letter {
  display: inline-block;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 131px;
  height: 4px;
}

/* Track
.custom-scrollbar::-webkit-scrollbar-track {

} */

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #929292;
}